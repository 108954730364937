import Block from "../../utils/block";
import Link from "../../components/link/link";

import Header from "../../components/header/header";

import router from "../../utils/router";

import privacy from "./privacy.tml";
import "./privacy.scss";

class Privacy extends Block {
  constructor(props: Record<string, any> = {}) {
    const header = new Header();

    const backToMainLink = new Link({
      name: "Вернуться назад",
      className: "privacy__backLink",
      events: {
        click: () => router.go("/")
      }
    })

    super("div", {
      header,
      backToMainLink,
      ...props
    });
  }

  render() {
    return this.setTemplate(privacy, this.props);
  }
}

export default Privacy;
