import Home from "./pages/home/home";
import Privacy from "./pages/privacy/privacy";

import router from "./utils/router";

import "./index.scss";

router
  .use("/", Home)
  .use("/privacy", Privacy)
  .start();
