import Handlebars from "handlebars";

Handlebars.registerHelper("menuList", function closeMenu() {
  return (
    `<ul class="header__menu-list">
      <li class="header__menu-item">
        <a href="/#sectionAbout" class="header__menu-link">О фирме</a>
      </li>
      <li class="header__menu-item">
        <a href="/#sectionServices" class="header__menu-link">Наши услуги</a>
      </li>
      <li class="header__menu-item">
        <a href="/#sectionCeilings" class="header__menu-link">Виды потолков</a>
      </li>
      <li class="header__menu-item">
        <a href="/#sectionOrder" class="header__menu-link">Как мы работаем</a>
      </li>
      <li class="header__menu-item">
        <a href="/#sectionForm" class="header__menu-link">Заказать обратный звонок</a>
      </li>
    </ul>`
  )
});

const header = Handlebars.compile(
  `<header class="header">
    <div class="container">
      <div class="header__wrapper">
        <picture>
          <img src="img/logo-mobile.png" class="header__logo" alt="Три квадрата" />
        </picture>
        {{{btnMenu}}}
      </div>
    </div>
    {{{menuList}}}
  </header>`
);

export default header;
