import Block from "../../utils/block";
import Button from "../button/button";

import header from "./header.tml";
import './header.scss'

interface IHeader {
}

class Header extends Block {
  constructor(props?: IHeader) {
    function smoothScroll() {
      const openMenu = document.querySelector(".header__menu-list") as HTMLDivElement;
      const closeIcon = document.querySelector(".header__menu-btn") as HTMLDivElement;
      return document.querySelectorAll(".header__menu-link").forEach(link => {
        link.addEventListener('click', function(e) {
          e.preventDefault();

          const href = link.getAttribute('href')?.slice(2) as string;

          const scrollTarget = document.getElementById(href);

          let topOffset = 212;

          if (window.innerWidth >= 1300) {
            topOffset = 0;
          }
          const elementPosition = scrollTarget?.getBoundingClientRect().top || 0;
          const offsetPosition = elementPosition - topOffset;

          if (window.innerWidth <= 1300) {
            openMenu.classList.remove("menu-show");
            closeIcon.classList.remove("close");
          }

          window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
          });
        });
      });
    }

    if (window.innerWidth >= 1300) {
      setTimeout(() => {
        smoothScroll();
      });
    }


    function showMenu() {
      const openMenu = document.querySelector(".header__menu-list") as HTMLDivElement;
      const closeIcon = document.querySelector(".header__menu-btn") as HTMLDivElement;
      if (window.innerWidth <= 1300) {
        openMenu.classList.toggle("menu-show");
        closeIcon.classList.toggle("close");
      }
      smoothScroll()
    }

    const btnMenu = new Button({
      class: "header__menu-btn",
      text: "Меню",
      events: {
        click: () => showMenu()
      }
    });

    super("div", { btnMenu, ...props });
  }

  render() {
    return this.setTemplate(header, this.props);
  }
}

export default Header;
