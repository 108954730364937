import Block from "../../utils/block";
import Button from "../../components/button/button";
import Link from "../../components/link/link";

import Header from "../../components/header/header";

import home from "./home.tml";
import "./home.scss";
import router from "../../utils/router";

class Home extends Block {
  constructor(props: Record<string, any> = {}) {
    const header = new Header();

    setTimeout(() => {
      document.querySelectorAll('a[href^="#"').forEach(link => {
        link.addEventListener('click', function(e) {
          e.preventDefault();

          const href = link.getAttribute('href')?.slice(1) as string;

          const scrollTarget = document.getElementById(href);

          const topOffset = 0;

          const elementPosition = scrollTarget?.getBoundingClientRect().top || 0;
          const offsetPosition = elementPosition - topOffset;

          window.scrollBy({
            top: offsetPosition,
            behavior: 'smooth'
          });
        });
      });

      const inputPhone = document.querySelector(".form__input--phone") as any;

      const prefixNumber = (str: string) => {
        if (str === "7") {
          return "7 (";
        }
        if (str === "8") {
          return "8 (";
        }
        if (str === "9") {
          return "7 (9";
        }
        return "7 (";
      };

      inputPhone?.addEventListener("input", (e: any) => {
        const value = inputPhone.value.replace(/\D+/g, "");
        const numberLength = 11;

        let result;
        if (inputPhone.value.includes("+8") || inputPhone.value[0] === "8") {
          result = "";
        } else {
          result = "+";
        }

        for (let i = 0; i < value.length && i < numberLength; i++) {
          switch (i) {
            case 0:
              result += prefixNumber(value[i]);
              continue;
            case 4:
              result += ") ";
              break;
            case 7:
              result += "-";
              break;
            case 9:
              result += "-";
              break;
            default:
              break;
          }
          result += value[i];
        }

        inputPhone.value = result;
      });
    });

    const ceilings = [
      {
        title: "Матовые",
        img: "./img/ceilings/matt.jpg",
        text: "Среди других разновидностей натяжных полотен – матовые потолки считаются классическим вариантом. Матовые полотна имеют достаточно низкую стоимость и очень просты в уходе, что делает крайне популярными среди наших клиентов."
      },
      {
        title: "Глянцевые",
        img: "./img/ceilings/glossy.jpg",
        text: "Данный вид потолков имеет высокие эстетические качество и отлично подходит для установки дома или в офисе. Полотно изготавливается из пленки ПВХ с глянцевой поверхностью и отлично подходят к интерьерам в стиле модерн или хай-тек. Данная продукция пользуется особой популярностью у наших клиентов и имеет доступную стоимость."
      },
      {
        title: "Сатиновые",
        img: "./img/ceilings/satin.jpg",
        text: "Данный вид натяжных потолков сочетает в себе лучшие качества глянцевых и матовых полотен. Это своеобразный гибрид, который достаточно часто выбирают для установки в квартире или собственном доме. Сатиновые полотна имеют матовую поверхность, но в то же время способны отражать свет."
      },
      {
        title: "Фактурные",
        img: "./img/ceilings/textured.jpg",
        text: "Компания располагает широким ассортиментом фактурных поверхностей и их цветовых решений. Благодаря сочетанию фактур можно получить необычные и стильные навесные системы, которые выгодно подчеркнут уникальность любого интерьера."
      },
      {
        title: "Фотопечать",
        img: "./img/ceilings/photo.jpg",
        text: "Предлагаем интересный вариант для тех, кому необходима идеально ровная поверхность потолка. Благодаря использованию специальной технологии удается создавать ровные поверхности без швов. Стоимость таких полотен немного превышают стандартные виды."
      },
      {
        title: "Тканевые",
        img: "./img/ceilings/tissue.jpg",
        text: "Это модели натяжных полотен, которые изготавливаются из полиэстерной ткани с ПВХ пропиткой. Тканевые полотна имеют очень эстетичный вид, отличные показатели прочности и надежности, а также не имеют швов."
      },
      {
        title: "Многоуровневые",
        img: "./img/ceilings/multilevel.jpg",
        text: "Создание многоуровневых потолочных конструкций на основе технологии натяжных потолков позволяет осуществлять монтаж очень сложных в техническом плане конструкций, которые имеют роскошный вид и привлекательный вид."
      },
      {
        title: "3D потолки",
        img: "./img/ceilings/3d.jpg",
        text: "Натяжные потолки 3D – это потолочные декоративные покрытия с иллюзией трехмерного пространства. Эффект достигается путем установки подвесной конструкции из полимерного материала. "
      },
      {
        title: "С освещением",
        img: "./img/ceilings/backlight.jpg",
        text: "Натяжной потолок с подсветкой – это отличное решения для того, чтобы разнообразить интерьер любого помещения будь то конференц-зал, бассейн, офис, гостиная, спальня, ванная или детская. Используя светодиодные ленты, можно разделить помещение на функциональные зоны, выделив световым эффектом место для отдыха, работы, встречи гостей или какой-нибудь отдельный элемент интерьера. "
      },
      {
        title: "Противопожарные",
        img: "./img/ceilings/fire.jpg",
        text: "Противопожарные потолки - это новое слово в технологиях безопасности натяжных потолков."
      },
    ];

    function works() {
      let worksArr = [];
      for(let i = 1; i<=25; i++) {
        if (i <= 9) {
          worksArr.push({img: `./img/works/${'0' + i}.jpg`});
        } else {
          worksArr.push({img: `./img/works/${i}.jpg`});
        }

      }
      return worksArr;
    }

    function ceilingsShow() {
      works()
      const openMenu = document.querySelector(".ceilings__items") as HTMLDivElement;
      openMenu.classList.add("ceilings__items-show");
    }

    function worksShow() {
      works()
      const openMenu = document.querySelector(".works__items") as HTMLDivElement;
      openMenu.classList.add("works__items-show");
    }

    const btnCeilingsShow = new Button({
      class: "ceilings__btn",
      text: "Посмотреть все",
      events: {
        click: () => ceilingsShow()
      }
    });

    const btnWorksShow = new Button({
      class: "works__btn",
      text: "Посмотреть ещё",
      events: {
        click: () => worksShow()
      }
    });

    const privacyLink = new Link({
      name: "Политикой кофиденциальности",
      className: "form__checkbox-link",
      events: {
        click: () => router.go("/privacy")
      }
    })




    super("div", {
      header,
      ceilings,
      works,
      btnCeilingsShow,
      btnWorksShow,
      privacyLink,
      ...props
    });
  }

  render() {
    return this.setTemplate(home, this.props);
  }
}

export default Home;
