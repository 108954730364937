import Handlebars from "handlebars";

const privacy = Handlebars.compile(
  `<div class="wrapper">
    <section class="privacy">
      {{{header}}}
      <div class="container">
        <h2>Согласие на обработку персональных данных</h2>
        <p>Пользователь, оставляя заявку на интернет-сайте <a class="privacy__link" href="#">Три квадрата</a>, принимает настоящее Согласие на обработку персональных данных (далее – Согласие). Действуя свободно, своей волей и в своем интересе, а также подтверждая свою дееспособность, Пользователь дает свое согласие на обработку своих персональных данных со следующими условиями:</p>
        <ol>
          <li>Данное Согласие дается на обработку персональных данных, как без использования средств автоматизации, так и с их использованием.</li>
          <li>Согласие дается на обработку следующих моих персональных данных:
            <ol>
              <li>Персональные данные, не являющиеся специальными или биометрическими: Фамилия, имя, отчество, номера контактных телефонов, адреса электронной̆ почты, адрес проживания и другие персональные данные, которые пользователь может по своему желанию указать в графе «комментарий».</li>
              <li>Пользовательские данные (сведения о местоположении, тип и версия ОС, тип и версия Браузера, тип устройства и разрешение его экрана, источник трафика, т.е. откуда пришел на сайт пользователь, с какого сайта или по какой рекламе, язык ОС и Браузера, какие страницы открывает и на какие кнопки нажимает пользователь, ip-адрес. )</li>
            </ol>
          </li>
          <li>Персональные данные не являются общедоступными.</li>
          <li>Цель обработки персональных данных: обработка входящих запросов физических лиц с целью оказания консультирования, аналитики действий физического лица на веб-сайте и функционирования веб-сайта, проведение рекламных и новостных рассылок.</li>
          <li>Основанием для обработки персональных данных является: ст. 24 Конституции Российской Федерации; ст.6 Федерального закона №152-ФЗ «О персональных данных».</li>
          <li>В ходе обработки с персональными данными будут совершены следующие действия: сбор, запись, систематизация, накопление, хранение, уточнение (обновление, изменение), извлечение, использование, удаление, уничтожение. </li>
          <li>Согласие может быть отозвано субъектом персональных данных или его представителем путем направления письменного заявления на электронную почту. </li>
        </ol>
        {{{backToMainLink}}}
      </div>
    </section>
  </div>`
);

export default privacy;
