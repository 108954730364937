import Handlebars from "handlebars";

const home = Handlebars.compile(
  `<div class="wrapper">
    {{{header}}}
    {{getGet}}
    <section class="hero">
      <div class="container">
        <aside class="hero__contacts">
          <address class="hero__address">
            <a href="#sectionMap">г.Армавир, ул.Ефремова, 164</a>
          </address>
          <tel class="hero__phone">
            <a href="tel:89189786000">8(918)978-60-00</a>
          </tel>
          <tel class="hero__phone">
            <a href="tel:89189786111">8(918)978-61-11</a>
          </tel>
          <a href="https://www.instagram.com/tri_kvadrata_arm/" class="hero__social">Мы в инстаграме</a>
        </aside>
        <h1 class="hero__title">Качественные натяжные потолки, рулонные шторы, жалюзи и комплектующие</h1>
        <a href="#sectionForm" class="hero__btn">Оставить заявку<span class="hero__sup"> *</span></a>
        <span class="hero__description"><span class="hero__sup">* </span>Выезд замерщика бесплатный</span>
      </div>
    </section>
    <section class="benefits">
      <div class="container">
        <h2 class="visually-hidden">Преимущества</h2>
        <ul class="benefits__list">
          <li class="benefits__item benefits__item--calendar">Более ${new Date().getFullYear() - 2008} лет на рынке</li>
          <li class="benefits__item benefits__item--worker">Опытные специалисты</li>
          <li class="benefits__item benefits__item--award">Гарантия 10 лет</li>
          <li class="benefits__item benefits__item--tag">Доступные цены</li>
        </ul>
      </div>
    </section>
    <section class="about" id="sectionAbout">
      <div class="container">
        <h2 class="visually-hidden">О фирме</h2>
        <p class="about__text">Фирма "Три квадрата" осуществляет деятельность с 2008 года. Мы профессионально занимаемся установкой натяжных потолков, жалюзи, рулонных штор, карнизов, светильников. Опытные мастера бесплатно проведут замер, а менеджеры компании предоставят развернутую консультацию по выбору натяжных потолков и комплектующих. Воспользоваться нашими услугами могут клиенты на территории Краснодарского края, Ставропольского края и республики Адыгея.</p>
        <a href="#sectionForm" class="about__btn">Заказать звонок</a>
      </div>
    </section>
    <section class="services" id="sectionServices">
      <div class="container">
        <h2 class="services__title">Наши услуги</h2>
        <ul class="services__list">
          <li class="services__item">
            <h4 class="services__item-title">Установка натяжного потолка</h4>
            <p class="services__item-text">Процесс установки потолка начинается с замера и завершается креплением декоративной ленты на местах стыков потолка и стены. Монтаж потолка выполняется специалистами компании, прошедшими обучение и обладающими большим опытом установки. Для монтажа используются современные инструменты и материалы высокого качества.</p>
          </li>
          <li class="services__item">
            <h4 class="services__item-title">Демонтаж натяжного потолка</h4>
            <p class="services__item-text">Демонтаж натяжного потолка требуется при ремонте, установке нового полотна, полной замене системы, необходимости ремонта коммуникаций или плит перекрытия. При сохранении качества пленки (отсутствие порезов, трещин, выгоревших, деформированных участков), возможно ее использование повторно.</p>
          </li>
          <li class="services__item">
            <h4 class="services__item-title">Слив воды с натяжного потолка</h4>
            <p class="services__item-text">Слив воды может потребоваться в случае протечки сверху. Процедура включает в себя частичный демонтаж потолка, чистку, просушку и обработку потолка противогрибковым средством и монтаж потока.</p>
          </li>
          <li class="services__item">
            <h4 class="services__item-title">Подбор комплектующих и освещения</h4>
            <p class="services__item-text">В том, насколько натяжные потолки долго не потребуют замены, огромную роль играет правильный выбор комплектующих для них. Ну а красота (эстетичный и привлекательный внешний вид потолков – это вторая по важности, после практичности, причина, побуждающая установить такие потолки) во многом зависит от их освещения.</p>
          </li>
        </ul>
      </div>
    </section>
    <section class="ceilings" id="sectionCeilings">
      <div class="container">
        <h2 class="ceilings__title">Виды потолков</h2>
        <div class="ceilings__slider">
          <ul class="ceilings__items items" id="items">
            {{#each ceilings}}
              <li class="ceilings__item">
                <h4 class="ceilings__item-title">{{title}}</h4>
                <img class="ceilings__img" src="{{img}}" alt="{{title}}" width="276" heiht="207">
                <p class="ceilings__item-text">{{text}}</p>
              </li>
            {{/each}}
          </ul>
          {{{btnCeilingsShow}}}
        </div>
      </div>
    </section>
    <section class="order" id="sectionOrder">
      <div class="container">
        <h2 class="order__title">Как мы работаем</h2>
        <ul class="order__list">
          <li class="order__item">
            <h4 class="order__item-title order__item-title--one">Заявка</h4>
            <p class="order__item-text">Вы обращаетесь к нам, позвонив, либо посетив наш офис. Менеджер консультирует вас по всем возникающим вопросам и оговаривет все условия работы нашей компании</p>
          </li>
          <li class="order__item">
            <h4 class="order__item-title order__item-title--two">
            Бесплатный замер</h4>
            <p class="order__item-text">Если Вы решили осуществить заказ натяжных потолков именно у нас, вы вызываете мастера по замерам. Вызов замерщика производится бесплатно.</p>
          </li>
          <li class="order__item">
            <h4 class="order__item-title order__item-title--three">Договор</h4>
            <p class="order__item-text">После снятия замеров ваших помещений и определения масштабов работа, заключается договор на изготовление и установку натяжных потолков. Точная дата установки определяется непосредственно в момент заключения договора.</p>
          </li>
          <li class="order__item">
            <h4 class="order__item-title order__item-title--four">Производство</h4>
            <p class="order__item-text">Далее, ваш заказ попадает на производство, где по индивидуальному заказу, учитывая каждую особенность и все ваши пожелания, изготавливается заказ. На срок установки натяжных потолков оказывает влияние сложность вашего заказа, технологические возможности его осуществления, количество помещений и другие факторы.</p>
          </li>
          <li class="order__item">
            <h4 class="order__item-title order__item-title--five">Монтаж</h4>
            <p class="order__item-text">После того, как ваш заказ будет готов, в согласованный день приедет квалифицированная бригада нашей компании. Они произведут монтаж натяжных потолков, максимально аккуратно относясь к обстановке помещения, не оставляя за собой следов своей работы.</p>
          </li>
          <li class="order__item">
          <h4 class="order__item-title order__item-title--done">Сдача работ</h4>
          <p class="order__item-text">После выполнения всех работ, вы оплачиваете все оказанные услуги согласно договора!</p>
        </li>
        </ul>
      </div>
    </section>
    <section class="works" id="sectionWorks">
      <div class="container">
        <h2 class="works__title">Наши работы</h2>
        <div class="works__slider">
          <ul class="works__items worksItems" id="worksItems">
            {{#each works}}
              <li class="works__item">
                <img class="works__img" src="{{img}}" alt="Одна из наших работ" width="306" heiht="236">
              </li>
            {{/each}}
          </ul>
          {{{btnWorksShow}}}
        </div>
      </div>
    </section>
    <section class="form" id="sectionForm">
      <div class="container">
        <h2 class="form__title">Остались вопросы?</h2>
        <p class="form__description">Оставьте свои контакты и наш специалист ответит на все ваши вопросы</p>
        <form class="form__fields" method="POST" action="/">
          <input type="text" name="name" class="form__input" required maxlength="20" placeholder="Имя"/>
          <input type="tel" name="phone" class="form__input form__input--phone" required placeholder="Телефон"/>
          <textarea rows="3" name="comment" class="form__comment" placeholder="Комментарий (необязательно)"></textarea>
          <button type="submit" class="form__btn">Отправить</button>
          <span class="form__checkbox-label">Отправляя данную форму вы соглашаетесь с {{{privacyLink}}}</span>
          <div class="wpcf7-response-output"></div>
        </form>
        <span class="form__back-label">Или позвоните нам</span>
        <tel class="form__phone-label">
          <a href="tel:89189786000">8(918)978-60-00</a>
        </tel>
        <tel class="form__phone-label">
          <a href="tel:89189786111">8(918)978-61-11</a>
        </tel>
      </div>
    </section>
    <section class="map" id="sectionMap">

      <iframe src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d705.3510426366792!2d41.107591729261294!3d44.99641374705725!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x40f9ec608d40bdd9%3A0x82eac6558cce0e45!2z0YPQuy4g0JXRhNGA0LXQvNC-0LLQsCwgMTY0LCDQkNGA0LzQsNCy0LjRgCwg0JrRgNCw0YHQvdC-0LTQsNGA0YHQutC40Lkg0LrRgNCw0LksIDM1MjkzMQ!5e0!3m2!1sru!2sru!4v1667299832006!5m2!1sru!2sru" aria-hidden="false" height="700" style="border:0; margin-top: -160px; margin-bottom: -200px;" allowfullscreen="" loading="lazy" referrerpolicy="no-referrer-when-downgrade"></iframe>

    </section>
    <footer class="contacts">
      <h4 class="contacts__address-title">Наш адрес:</h4>
      <address class="contacts__address">г.Армавир, ул.Ефремова, 164</address>
      <h4 class="contacts__clock">Время работы: Пн-Сб, с 9:00 до 18:00</h4>
      <a class="contacts__email" href="mailto:plushnikov.a@mail.ru">plushnikov.a@mail.ru</a>
      <picture>
        <img src="img/logo-mobile.svg" class="contacts__logo" alt="Три квадрата" width="250" height="49" />
      </picture>
      <a href="https://www.instagram.com/tri_kvadrata_arm/" class="contacts__social">Мы в инстаграме</a>
    </footer>
  </div>`
);

export default home;
